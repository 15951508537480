<template>
  <confirm-modal-container
    v-if="show"
    class="select-language-modal"
    @close="close"
  >
    <template #header>
      Language
    </template>

    <template #content>
      <div class="select-language-modal__content">
        <div
          v-for="lang in MixinNavLangSwitch_languages"
          :key="lang.id"
          class="select-language-modal__item flx flx--row flx--items-center txt txt--16px txt--height-20px txt--pointer"
          @click="() => selectLanguageAndCloseModal(lang)"
        >
          {{ lang.name }}

          <svg
            v-if="lang.code === MixinNavLangSwitch_currentLocale"
            width="14"
            height="10"
            viewBox="0 0 14 10"
            ill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12.8119 0.273129C12.3835 -0.116316 11.7173 -0.0857947 11.3257 0.344681L4.90519 7.41467L1.80332 4.26127C1.39386 3.84656 0.728671 3.84024 0.315022 4.24863C-0.0996889 4.65597 -0.106006 5.32222 0.302389 5.73693L4.18522 9.68398C4.38416 9.88606 4.65359 9.9987 4.93565 9.9987C4.94197 9.9987 4.94935 9.99869 4.95567 9.99976C5.24617 9.99344 5.51985 9.86923 5.71454 9.65453L12.8834 1.76042C13.274 1.32883 13.2424 0.663636 12.8119 0.273129Z" fill="#A5A1FF"/>
          </svg>
        </div>
      </div>
    </template>
  </confirm-modal-container>
</template>

<script>
import MixinNavLangSwitch from '@/mixins/MixinNavLangSwitch'

export default {
  components: {
    ConfirmModalContainer: () => import(/* webpackMode: "eager" */ '@/components/containers/ConfirmModalContainer.vue'),
  },
  mixins: [MixinNavLangSwitch],
  props: {
    show: { type: Boolean, default: false },
  },
  methods: {
    close () { this.$emit('close') },
    selectLanguageAndCloseModal (lang) {
      this.MixinNavLangSwitch_onClickLang(lang)
      this.close()
    },
  },
}
</script>

<style lang="scss" scoped>
.select-language-modal {
  // max-width: toRem(356px);

  &__content {
    margin-top: toRem(24px);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: toRem(20px) toRem(45px);

    @include bp(m) {
      gap: toRem(20px) toRem(60px);
    }
  }

  &__item {
    color: color('color-05');
    gap: toRem(5px);
    transition-duration: .25s;

    &:hover {
      color: color('color-10');
    }
  }

  ::v-deep {
    .Modal__container {
      max-width: toRem(356px) !important;
      min-width: unset !important;
      min-height: unset !important;
      width: 100%;
      height: fit-content;
    }
  }
}
</style>
